import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueSweetAlert from 'vue-sweetalert'
import vSelect from 'vue-select'
import App from './App'
import routes from 'client/routes'
import VueI18n from 'vue-i18n'
import VuexStore from './stores/index'
import { sync } from 'vuex-router-sync'
import VeeValidate from 'vee-validate'
import ElementUI from 'element-ui'
import fullscreen from 'vue-fullscreen'
import locale from 'element-ui/lib/locale/lang/ko'
import moment from 'moment-timezone'
import 'moment/locale/ko'
import koMessages from '../locales/ko.json'
import enMessages from '../locales/en.json'
import { getLocalesWithoutDot } from './utils'
import { setAccessToken } from './misc/token'
import { datadogRum } from '@datadog/browser-rum'
import { version } from './version'

// FontAwesome
import initFontAwesome from './fontawesome'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'styles/index.scss'
import toastr from 'toastr'
import Keycloak from 'keycloak-js'

import jQuery from 'jquery'
import 'jquery-ui'
import 'jquery-migrate'
import 'bootstrap'
import axios from 'axios'

import config from './config'
import trimInput from './listeners/trimInput'

// jQuery 3.5로 마이그레이션 하면 self-closing tag를 추가할 경우 자동으로 닫히지 않는다.
// https://jquery.com/upgrade-guide/3.5/#jquery-htmlprefilter-changes
// TUI v1이 더이상 업데이트 되지 않고 있고, jQuery 3.3 대상으로 만들어져 있어 에디터 HTML이 깨진다.
// https://github.com/nhn/tui.editor/blob/v1.4.1/src/js/layout.js#L73
// 이에 대한 대응으로 HTML 필터를 3.3 버전으로 적용한다.
jQuery.UNSAFE_restoreLegacyHtmlPrefilter()

// Moment config
moment.tz.setDefault('Asia/Seoul')
moment.locale('ko')

// Fontawesome init
initFontAwesome()

// Toastr config
toastr.options = {
  positionClass: 'toast-position',
}

// Vue Config
if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true
}

// locale
const messages = {
  ko: {
    message: getLocalesWithoutDot(koMessages),
  },
  en: {
    message: getLocalesWithoutDot(enMessages),
  },
}

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueI18n)
Vue.use(VueSweetAlert)
Vue.use(VeeValidate, { fieldsBagName: 'vee-fields' })
Vue.use(ElementUI, { locale })
Vue.use(fullscreen)
Vue.component('v-select', vSelect)
Vue.component('fa', FontAwesomeIcon)

Vue.filter('numeric', v => {
  return !v ? v : v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.mixin({
  mounted() {
    trimInput()
  },
})

export const i18n = new VueI18n({
  locale: 'ko',
  messages,
})

// Vue Router
const router = new VueRouter({
  routes,
  hashbang: false,
  mode: 'history',
  linkActiveClass: 'active',
})
// Browser Title Update
router.beforeEach((to, from, next) => {
  next()
  if (to.meta != null && to.meta.title != null) {
    document.title = `${i18n.t(to.meta.title)} - DevPlay Portal`
  } else {
    document.title = 'DevPlay Portal'
  }
})

// Sync Vuex & Vue-Router
sync(VuexStore, router)

async function main() {
  // Frontend 설정값 받아오기
  await config.loadRemoteConfig()

  // API Endpoint 주소 설정
  axios.defaults.baseURL = config.apiEndpoint
  window.apiEndpoint = config.apiEndpoint // TODO(hodduc): 일단 땜빵

  if (process.env.NODE_ENV === 'production') {
    let rumEnvironment = 'local'
    const locationHost = window.location.host
    if (locationHost.endsWith('devsisters.cloud')) {
      rumEnvironment = 'origin'
    } else if (locationHost.endsWith('devsisters.systems')) {
      const nameMapping = { portal: 'clone', portal2: 'clone2', portal3: 'clone3' }
      rumEnvironment = locationHost.substring(0, locationHost.indexOf('.')).substring
      rumEnvironment = rumEnvironment in nameMapping ? nameMapping[rumEnvironment] : 'clone'
    }

    datadogRum.init({
      applicationId: '592d139d-8e02-440a-86d5-6790ea48963b',
      clientToken: 'pube5a099a2f1a7eb39c08af0a60c211a1b',
      site: 'datadoghq.com',
      service: 'portal',
      env: rumEnvironment,
      version: version.lastCommit,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
    datadogRum.startSessionReplayRecording()
  }

  if (process.env.NODE_ENV !== 'production' && !Number(process.env.USE_KEYCLOAK)) {
    // 로컬 개발환경 전용 && 키클록을 사용하지 않을 때 (디버그 로그인 페이지 사용)
    const DebugLogin = () =>
      import(/* webpackChunkName: "components_debug_login" */ 'client/components/User/DebugLogin')

    const debugLoginRoute = {
      path: '/login',
      component: DebugLogin,
      name: 'debug-login',
    }
    router.addRoutes([debugLoginRoute])

    new Vue({
      el: '#app',
      render: h => h(App),
      router,
      i18n,
      store: VuexStore,
    })
  } else {
    // Keycloak 객체 생성
    const keycloak = new Keycloak({
      url: config.keycloakUrl,
      realm: config.keycloakRealmName,
      clientId: config.keycloakClientId,
      scope: 'openid email profile phone',
    })

    // Keycloak 인증 시작
    keycloak.init({ onLoad: 'login-required' }).then(async () => {
      setAccessToken(keycloak.token)
      setInterval(() => {
        // Access token auto refresh
        // 20초마다 유효기간이 21초 미만으로 남았는지 확인하고 갱신
        keycloak.updateToken(21).then(() => {
          setAccessToken(keycloak.token)
        })
      }, 20000)

      // KeyCloak 인증을 다 마친 뒤에 Vue app 생성
      /* eslint-disable no-new */
      new Vue({
        el: '#app',
        render: h => h(App),
        router,
        i18n,
        store: VuexStore,
      })
    })
  }
}

main()
