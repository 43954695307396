import axios from 'axios'
import Notify from 'element-ui/lib/notification'
import { getAccessToken } from 'client/misc/token'

//
// API Endpoints
//

// Portal API
export * from './games'
export * from './gamecenter'
export * from './builds'
export * from './documents'
export * from './ecr'
export * from './endpoints'
export * from './environments'
export * from './update'
export * from './maintenance'
export * from './notices'
export * from './resources'
export * from './releases'
export * from './servers'
export * from './monitoring'
export * from './coupon'
export * from './analyticsreport'
export * from './wordfilter'

// Reception API
export * from './reception'
export * from './terms'

// Others
export * from './etc'

const notify = (message, type = 'error') => {
  Notify({
    type,
    message,
    dangerouslyUseHTMLString: true,
    duration: 10000, // 10sec
  })
}

//
// Axios configurations
//
axios.interceptors.request.use(
  config => {
    const token = getAccessToken()
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  },
)

export function newAxiosSessionInstance() {
  const ax = axios.create()
  ax.interceptors.request.use(
    config => {
      const token = getAccessToken()
      if (token != null) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    error => {
      // Do something with request error
      return Promise.reject(error)
    },
  )

  return ax
}

export function handleError(error) {
  const response = error.response

  if (!response?.status) {
    return Promise.reject(error)
  }

  // Forbidden 이면 Login으로 페이지를 전환한다
  if (response.status === 401) {
    if (
      location.pathname !== '/login/' &&
      process.env.NODE_ENV !== 'production' &&
      process.env.USE_KEYCLOAK !== '1'
    ) {
      location.href = '/login/'
    }
    return Promise.reject('Unauthorized')
  }

  // 403일경우 권한이 없다는 메세지를 띄움
  if (response.status === 403) {
    if (response.data.errors && response.data.errors.length > 0) {
      notify(response.data.errors[0])
    } else if (response.data['detail']) {
      notify(response.data['detail'])
    } else if (Array.isArray(response.data) && response.data.length > 0) {
      notify(response.data.join('<br>'))
    } else {
      notify('권한이 없습니다. 관리자에게 문의 바랍니다.')
    }
    return Promise.reject('Unauthorized')
  }

  // non_field_error 일경우 그대로 출력
  if (response.data.non_field_errors) {
    notify(`HTTP ${response.status}<br/>${response.data.non_field_errors}`)
    return Promise.reject(error)
  }

  // detail을 갖고 있는 경우 detail을 출력
  if (response.data.detail) {
    notify(`HTTP ${response.status}<br/>${response.data.detail}`)
    return Promise.reject(error)
  }

  let errStr = ''
  if (Array.isArray(response.data)) {
    for (const reason of response.data) {
      errStr += `<br/>${reason}`
    }
  } else if (typeof response.data === 'object') {
    for (const key in response.data) {
      errStr += `<br/>${key}: ${response.data[key]}`
    }
  } else if (typeof response.data === 'string') {
    errStr = `\n${response.data}`
  }
  notify(`HTTP ${response.status}${errStr}`)
  return Promise.reject(error)
}
axios.interceptors.response.use(
  response => response,
  error => handleError(error),
)

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

axios.defaults.withCredentials = true
